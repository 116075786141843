jQuery(function () {
    var myOffside = offside("#mobileMenu", {
        slidingElementsSelector: "body",
        buttonsSelector: ".menu-toggle, .offcanvas-close, .offcanvas-overlay",
        beforeOpen: function () {
            $(".offcanvas-overlay").fadeIn();
        },
        beforeClose: function () {
            $(".offcanvas-overlay").fadeOut();
        },
    });
});

const menuMapping = new MappingListener({
    selector: ".menu-wrapper",
    mobileWrapper: ".offcanvas-wrap",
    mobileMethod: "appendTo",
    desktopWrapper: ".header-left-wrap",
    desktopMethod: "prependTo",
    breakpoint: 1024.98,
}).watch();

const languageMapping = new MappingListener({
    selector: ".language-wrapper",
    mobileWrapper: ".offcanvas-wrap",
    mobileMethod: "appendTo",
    desktopWrapper: ".hotline-wrapper",
    desktopMethod: "insertBefore",
    breakpoint: 1024.98,
}).watch();

const newsSlider = new Swiper(".news-slider .swiper-container", {
    slidesPerView: 3,
    spaceBetween: 30,
    breakpoints: {
        1024: {
            slidesPerView: 2,
            spaceBetween: 30,
        },
        576: {
            slidesPerView: 1.25,
            spaceBetween: 15,
        },
    },
    navigation: {
        nextEl: ".news-slider .swiper-next",
        prevEl: ".news-slider .swiper-prev",
    },
});

const projectThumbnailsSlider = new Swiper(
    ".project-detail-thumbnails .swiper-container",
    {
        slidesPerView: 5,
        spaceBetween: 16,
        navigation: {
            nextEl: ".project-detail-thumbnails .swiper-next",
            prevEl: ".project-detail-thumbnails .swiper-prev",
        },
        breakpoints: {
            768: {
                slidesPerView: 4,
            },
            576: {
                slidesPerView: 2,
            },
        },
    }
);

const projectImagesSlider = new Swiper(
    ".project-detail-images .swiper-container",
    {
        slidesPerView: 1,
        spaceBetween: 15,
        navigation: {
            nextEl: ".project-detail-thumbnails .swiper-next",
            prevEl: ".project-detail-thumbnails .swiper-prev",
        },
        thumbs: {
            swiper: projectThumbnailsSlider,
        },
    }
);

const videoSlider = new Swiper(".video-slider .swiper-container", {
    slidesPerView: 2.25,
    spaceBetween: -160,
    centeredSlides: true,
    initialSlide: 1,
    loop: true,
    breakpoints: {
        768: {
            slidesPerView: 2.25,
            spaceBetween: 15,
        },
        576: {
            slidesPerView: 1.5,
            spaceBetween: 15,
        },
    },
    navigation: {
        prevEl: ".video-slider .swiper-prev",
        nextEl: ".video-slider .swiper-next",
    },
});

const about5Slider = new Swiper(".about-5 .swiper-container", {
    slidesPerView: 1,
    spaceBetween: 30,
    navigation: {
        prevEl: ".about-5 .swiper-prev",
        nextEl: ".about-5 .swiper-next",
    },
    pagination: {
        el: ".about-5 .swiper-pagination",
        clickable: true,
        renderBullet: function (index, className) {
            return (
                '<span class="' +
                className +
                '">' +
                "0" +
                (index + 1) +
                "</span>"
            );
        },
    },
});

const yearContentSlider = new Swiper(".year-content-slider .swiper-container", {
    slidesPerView: 1,
    spaceBetween: 30,
});

const yearDotSlider = new Swiper(".year-dot-slider .swiper-container", {
    slidesPerView: 5,
    spaceBetween: 30,
    centeredSlides: true,
    slideToClickedSlide: true,
    navigation: {
        prevEl: ".year-dot-slider .swiper-prev",
        nextEl: ".year-dot-slider .swiper-next",
    },
    breakpoints: {
        1024: {
            slidesPerView: 3,
            spaceBetween: 15,
        },
        576: {
            slidesPerView: 2,
            spaceBetween: 15,
        },
    },
});

try {
    yearContentSlider.controller.control = yearDotSlider;
    yearDotSlider.controller.control = yearContentSlider;
} catch (error) {}

const about8Slider = new Swiper(".about-8 .swiper-container", {
    slidesPerView: 2,
    spaceBetween: 30,
    navigation: {
        prevEl: ".about-8 .swiper-prev",
        nextEl: ".about-8 .swiper-next",
    },
    breakpoints: {
        768: {
            slidesPerView: 1.5,
            spaceBetween: 15,
        },
        576: {
            slidesPerView: 1.15,
            spaceBetween: 15,
        },
    },
});

$(".home-project-slider-1 .slick-slider").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    infinite: true,
    asNavFor: ".home-project-slider-2 .slick-slider",
    prevArrow: '<div class="slider-btn-2 slider-btn-prev" tabindex="0"><em class="fal fa-long-arrow-left"></em></div>',
    nextArrow: '<div class="slider-btn-2 slider-btn-next" tabindex="0"><em class="fal fa-long-arrow-right"></em></div>'
});

$(".home-project-slider-2 .slick-slider").slick({
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    infinite: true,
    asNavFor: ".home-project-slider-1 .slick-slider",
    focusOnSelect: true
});

const partnerSlider = new Swiper(".partner-slider .swiper-container", {
    slidesPerView: 4,
    slidesPerColumn: 2,
    spaceBetween: 20,
    navigation: {
        prevEl: ".home-5 .swiper-prev",
        nextEl: ".home-5 .swiper-next",
    },
    breakpoints: {
        768: {
            slidesPerView: 3,
            spaceBetween: 15,
        },
        576: {
            slidesPerView: 2,
            spaceBetween: 15,
        },
    },
});
